import React, { useState } from "react";
import uuid from 'react-uuid';
import '../css/ActionRequestForm.css';

export const ActionRequestForm = ({ apiKey, environment, token, clientKey, onResponse }) => {
  const [type, setType] = useState("PUT");
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [reuseHandle, setReuseHandle] = useState(false);
  const [inputHandle, setInputHandle] = useState("");
  const [body, setBody] = useState("");
  const [action1, setAction1] = useState("");
  const [action2, setAction2] = useState("");
  const [sound, setSound] = useState("default");
  const [snoozeEnabled, setSnoozeEnabled] = useState("");
  const [omitSnooze, setOmitSnooze] = useState(false);
  const [errors, setErrors] = useState(null);

  const createRequestBody = () => {
    const actions = createActionsObject();
    const reqBody = {};
    const snoozeObj = {};

    if (snoozeEnabled === "" || snoozeEnabled === null) {
      snoozeObj.enabled = null;
    } else {
      snoozeObj.enabled = snoozeEnabled === "true";
    }

    reqBody.title = title || null;
    reqBody.body = body || null;
    reqBody.actions = actions;
    reqBody.sound = sound === "none" ? null : sound;

    if (!omitSnooze) {
      reqBody.snooze = snoozeObj;
    }

    return reqBody;
  };

  const createResponseMessage = (message, handle) => {
    const date = new Date();
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}-`;
    return `${time}${message} (Handle: ${handle})`;
  };

  const parseActionInput = (input, fieldId) => {
    if (input !== "") {
      try {
        const escaped = input.replace(/(\r\n|\n|\r)/gm, "");
        return JSON.parse(escaped);
      } catch {
        const newError = `Error parsing action ${fieldId}, invalid JSON`;
        setErrors(newError);
        return null;
      }
    }
    return null;
  };

  const createActionsObject = () => {
    let actionsObject = [];
    setErrors(null);

    if (action1 !== "") {
      const parsed = parseActionInput(action1, "1");
      if (parsed) actionsObject.push(parsed);
    }
    if (action2 !== "") {
      const parsed = parseActionInput(action2, "2");
      if (parsed) actionsObject.push(parsed);
    }

    return actionsObject;
  };

  const sendActionRequest = () => {
    const isProduction = environment === "production";
    const baseUrl = isProduction
      ? "https://platform.driveaxleapp.com/api"
      : "https://squid-fortress-staging.eleostech.com/api";

    const requestBody = createRequestBody();
    if (requestBody.actions.some(action => action === null)) return;

    const requestOptions = isProduction
      ? {
        method: type,
        headers: {
          "Authorization": `Key key=${apiKey}`,
          "Content-Type": "application/json",
        },
      }
      : {
        method: type,
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Eleos-Admin-Client-Key": clientKey || "",
        },
      };

    if (type === "PUT") {
      requestOptions.body = JSON.stringify(requestBody);
    }

    if (isProduction && (!apiKey || apiKey.trim() === "")) {
      alert("API Key is required in production.");
      return;
    }

    if (!isProduction && (!token || !clientKey)) {
      alert("Token and Client Key are required in staging.");
      return;
    }

    let handle = reuseHandle && inputHandle ? inputHandle : uuid();
    if (!reuseHandle) setInputHandle(handle);

    const requestUrl = `${baseUrl}/v1/action_requests/${username}/${handle}`;
    onResponse(`Request Sent to ${requestUrl}`);

    fetch(requestUrl, requestOptions)
      .then((resp) => {
        if (resp.status === 403) {
          localStorage.removeItem("token");
          window.location.reload();
          throw new Error("403 Forbidden");
        }
        return resp.text().then((response) => {
          const message = `Response Status: ${resp.status}, Response Body: ${response}`;
          onResponse(createResponseMessage(message, handle));
        });
      })
      .catch((error) => {
        const message = `Response: Error - ${error.message}`;
        onResponse(createResponseMessage(message, handle));
      });
  };

  const handleActionRequest = (event) => {
    event.preventDefault();
    if (!username.trim()) {
      alert("Please enter a username.");
      return;
    }
    sendActionRequest();
  };

  const handleClearForm = () => {
    setType("PUT");
    setUsername("");
    setTitle("");
    setReuseHandle(false);
    setInputHandle("");
    setBody("");
    setAction1("");
    setAction2("");
    setSound("default");
    setSnoozeEnabled("");
    setOmitSnooze(false);
    setErrors(null);
  };

  return (
    <div className="action-request-form">
      <h3>Action Request</h3>
      <form className="actionRequest-form-container" onSubmit={handleActionRequest}>
        <label className="actionRequest-form-label">
          Type:
          <select value={type} name="type" onChange={(e) => setType(e.target.value)} className="actionRequest-select-input">
            <option value="PUT">Create</option>
            <option value="DELETE">Delete</option>
          </select>
        </label>
        <label className="actionRequest-form-label">
          Username:
          <input
            name="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="actionRequest-text-input"
            placeholder="Enter username"
          />
        </label>
        <label className="actionRequest-form-label">
          Handle:
          <input
            name="handle"
            type="text"
            value={inputHandle}
            onChange={(e) => setInputHandle(e.target.value)}
            className="actionRequest-text-input"
            placeholder="Enter handle or leave blank for new UUID"
          />
        </label>
        <label className="actionRequest-form-label actionRequest-checkbox-label">
          Preserve Handle:
          <input
            name="reuseHandle"
            type="checkbox"
            checked={reuseHandle}
            onChange={(e) => setReuseHandle(e.target.checked)}
            className="actionRequest-checkbox-input"
          />
        </label>
        <label className="actionRequest-form-label">
          Title:
          <input
            disabled={type === "DELETE"}
            name="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="actionRequest-text-input"
            placeholder="Enter title"
          />
        </label>
        <label className="actionRequest-form-label">
          Body:
          <input
            disabled={type === "DELETE"}
            name="body"
            type="text"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            className="actionRequest-text-input"
            placeholder="Enter body"
          />
        </label>
        <label className="actionRequest-form-label">
          Sound:
          <select
            disabled={type === "DELETE"}
            value={sound}
            name="sound"
            onChange={(e) => setSound(e.target.value)}
            className="actionRequest-select-input"
          >
            <option value="none">None</option>
            <option value="default">Default</option>
            <option value="honk">Honk</option>
            <option value="guitar">Guitar</option>
            <option value="echo">Echo</option>
            <option value="marimba">Marimba</option>
          </select>
        </label>
        <label className="actionRequest-form-label">
          Snooze Enabled:
          <select
            disabled={type === "DELETE"}
            value={snoozeEnabled}
            name="snoozeEnabled"
            onChange={(e) => setSnoozeEnabled(e.target.value)}
            className="actionRequest-select-input"
          >
            <option value="">-- Select --</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </label>
        <label className="actionRequest-form-label actionRequest-checkbox-label">
          Omit Snooze Object:
          <input
            disabled={type === "DELETE"}
            name="omitSnooze"
            type="checkbox"
            checked={omitSnooze}
            onChange={(e) => setOmitSnooze(e.target.checked)}
            className="actionRequest-checkbox-input"
          />
        </label>
        {errors && <div className="actionRequest-error-message">{errors}</div>}
        <label className="actionRequest-form-label">
          <span>Action 1:</span>
          <textarea
            disabled={type === "DELETE"}
            cols={50}
            rows={3}
            name="action1"
            value={action1}
            onChange={(e) => setAction1(e.target.value)}
            className="actionRequest-textarea-input"
            placeholder="Enter JSON for Action 1"
          />
        </label>
        <label className="actionRequest-form-label">
          <span>Action 2:</span>
          <textarea
            disabled={type === "DELETE"}
            cols={50}
            rows={3}
            name="action2"
            value={action2}
            onChange={(e) => setAction2(e.target.value)}
            className="actionRequest-textarea-input"
            placeholder="Enter JSON for Action 2"
          />
        </label>
        <div className="actionRequest-button-group">
          <button type="submit" className="actionRequest-send-button">SEND</button>
          <button type="button" className="actionRequest-clear-button" onClick={handleClearForm}>CLEAR</button>
        </div>
      </form>
    </div>
  );
};