import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { verifyToken } from './components/AuthHandler';
import Mapplet from './views/Mapplet'
import { TestScripts } from './views/TestScripts'
import Navbar from './components/Navbar'
import { Provider } from 'react-redux'
import 'regenerator-runtime/runtime';
import store from './store';
import './css/Navbar.css'
import GoogleLogin from './components/GoogleLogin'
import jwt from 'jwt-decode'

const MainView = () => {
  const [user, updateUser] = useState(null);
  const [views,updateView] = useState([
      {
        name: 'Testscripts',
        view: <TestScripts />
      },
      {
        name: 'Geotab RC',
        view: <Mapplet />
      }
    ]);
  const [currentView, updateCurrentView] = useState(views[0]);

  const handleViewUpdate = (tempView) => {
      updateCurrentView(tempView);
  }

  useEffect(() => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      updateUser({ token: accessToken });
    } else {
      updateUser(null);
    }
  }, []);

  if(user){
    return (
      <Provider store={store}>
        <div id="MainView">
          <Navbar views={views} current={currentView} setCurrent={handleViewUpdate}/>
          {currentView.view}
        </div>
      </Provider>
    );
  }
  else {
    return (
        <GoogleLogin />
    );
  }
}


ReactDOM.render(
  <MainView />,
  document.getElementById('root')
)
module.hot.accept();
