import React, { useState } from "react";
import './css/UserRefreshForm.css';

export const UserRefreshForm = ({ apiKey, environment, token, clientKey, onResponse }) => {
  const [username, setUsername] = useState("");

  const sendRefreshRequest = () => {
    const isProduction = environment === "production";
    const baseUrl = isProduction
      ? "https://platform.driveaxleapp.com/api"
      : "https://squid-fortress-staging.eleostech.com/api";

    const requestOptions = isProduction
      ? {
        method: "POST",
        headers: {
          "Authorization": `Key key=${apiKey}`,
          "Content-Type": "application/json",
        },
      }
      : {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Eleos-Admin-Client-Key": clientKey || "",
        },
      };

    if (isProduction && (!apiKey || apiKey.trim() === "")) {
      alert("API Key is required in production.");
      return;
    }

    if (!isProduction && (!token || !clientKey)) {
      alert("Token and Client Key are required in staging.");
      return;
    }

    const requestUrl = `${baseUrl}/v1/users/${username}/updates`;
    const date = new Date();
    const time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}-`;

    onResponse(`${time}Request Sent to ${requestUrl}`);

    fetch(requestUrl, requestOptions)
      .then((response) => {
        if (response.status === 403) {
          localStorage.removeItem("token");
          window.location.reload();
          throw new Error("403 Forbidden");
        }
        const newResponse = `${time}Response: ${response.status}(${response.statusText})`;
        onResponse(newResponse);
      })
      .catch((error) => {
        const newResponse = `${time}Response: Error - ${error.message}`;
        onResponse(newResponse);
      });
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleRefreshRequest = () => {
    if (!username.trim()) {
      alert("Please enter a username.");
      return;
    }
    sendRefreshRequest();
  };

  return (
    <div className="user-refresh-form">
      <h3>User Refresh Request</h3>
      <div className="userRefresh-form-container">
        <div className="userRefresh-input-group">
          <label className="userRefresh-input-label">
            Username:
            <input
              name="username"
              type="text"
              value={username}
              onChange={handleUsernameChange}
              className="userRefresh-text-input"
              placeholder="Enter username"
            />
          </label>
          <button className="userRefresh-send-button" onClick={handleRefreshRequest}>
            SEND
          </button>
        </div>
      </div>
    </div>
  );
};