import React from 'react';
import uuid from 'react-uuid';
import '../css/TestScripts.css';
import CustomMessageForm from '../CustomMessageForm.js';
import TestScriptButtons from '../TestScriptButtons.js';
import { UserRefreshForm } from '../UserRefreshForm.js';
import { ActionRequestForm } from '../components/ActionRequestForm.js';
import { isError } from 'lodash';

const encodeNestedObjects = (params, prefix) => {
  var items = [];
  for (var field in params) {
    var key = prefix + "[" + field + "]";
    var type = typeof params[field];
    switch (type) {
      case "object":
        if (params[field] == null) {
          items.push(encodeURIComponent(key) + "=");
        }
        else {
          items = items.concat(encodeNestedObjects(params[field], key));
        }
        break;
      default:
        items.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[field]));
        break;
    }
  }

  return items.filter(x => x !== "").join("&");
}

const encodeFormData = (data) => {
  var url = Object.keys(data)
    .map(key => {
      if (key == "form_data" && data[key] !== "") {
        return encodeNestedObjects(JSON.parse(data[key]), key);
      }
      else {
        return encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      }
    })
    .join('&')
  return url;
}

class TestScripts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'messageApis',
      apiKey: "",
      token: localStorage.getItem("token"),
      environment: "staging",
      responseMessages: [],
      showApiKeyInput: false,
      responseMessages: [],
      clientKeys: [],
      clientKey: "",
      clientKeyInput: "",
      showSuggestions: false,
      isLoading: false
    }
    if (localStorage.getItem('apiKey') != null) {
      this.state['apiKey'] = localStorage.getItem('apiKey');
    }
    // bind methods
    this.makeFetchRequest = this.makeFetchRequest.bind(this);
    this.handleAPIChange = this.handleAPIChange.bind(this);
    this.handleENVChange = this.handleENVChange.bind(this);
    this.makeMessageRequest = this.makeMessageRequest.bind(this);
    this.toggleApiKeyInput = this.toggleApiKeyInput.bind(this);
    this.clearResponseLog = this.clearResponseLog.bind(this);
    this.handleClientChange = this.handleClientChange.bind(this);
    this.fetchClientKeys = this.fetchClientKeys.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.handleClientInputChange = this.handleClientInputChange.bind(this);
    this.handleClientSelect = this.handleClientSelect.bind(this);

    // tts message texts
    this.TtsText = {
      normalTTS: "Your trailer, 1 6 5 0 0 3, is reporting a reefer alarm. Code 2 0 0 0 0 1. LOW FUEL LEVEL WARNING. Fuel level is 27.0 gallons.",
      longTTS: "Are you listening to me? ".repeat(14) + "You reached the end of message. Congratulations!!!",
      pokemonTTS: "I want to be the very best, Like no one ever was. To catch them is my real test, To train them is my cause. I will travel across the land, Searching far and wide. Each Pokemon to understand The power thats inside. Pokemon. Gotta catch em all. Its you and me, I know its my destiny.",
      commonAbrevTTS: "The time is 11:00 pm the 17th of march. Order #149. It takes 14 hr to cook chicken. I weigh 14 lb and my cat weighs 90 kg",
      spanishTTS: "Tienes una nueva orden asignada: 724890"
    };
  }

  componentDidMount() {
    // Fetch client keys when component mounts if using token
    if (!this.state.showApiKeyInput && this.state.token && this.state.clientKeys.length === 0) {
      this.fetchClientKeys();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.environment !== this.state.environment ||
      prevState.showApiKeyInput !== this.state.showApiKeyInput
    ) {
      this.fetchClientKeys();
    }
  }

  fetchClientKeys() {
    if (this.state.environment !== "staging") {
      this.setState({ clientKeys: [], clientKey: "", clientKeyInput: "", isLoading: false });
      return;
    }

    this.setState({ isLoading: true });
    const endpoint = "https://squid-fortress-staging.eleostech.com/internal/client_groups/eleos";
    const headers = {
      "Authorization": `Bearer ${this.state.token}`,
      "Content-Type": "application/json"
    };

    fetch(endpoint, { headers })
      .then(response => {
        if (response.status === 403) {
          localStorage.removeItem('token');
          window.location.reload();
          throw new Error('403 Forbidden - Token cleared');
        }
        if (!response.ok) throw new Error(`Failed to fetch from ${endpoint}: ${response.status}`);
        return response.json();
      })
      .then(data => {
        const allKeys = data.clients.map(client => client.key);
        const defaultKey = allKeys.includes('reference-qa') ? 'reference-qa' : (allKeys.length > 0 ? allKeys[0] : "");
        this.setState({
          clientKeys: allKeys,
          clientKey: defaultKey,
          clientKeyInput: defaultKey,
          isLoading: false
        });
      })
      .catch(e => {
        console.error(e);
        this.setState({ clientKeys: [], clientKey: "", clientKeyInput: "", isLoading: false });
      });
  }

  makeFetchRequest(options, endPoint) {
    var newRespMessage;
    const handle = uuid();
    const url = this.state.environment === "staging" ? `https://squid-fortress-staging.eleostech.com/api/${endPoint}/${handle}` : `https://platform.driveaxleapp.com/api/${endPoint}/${handle}`;
    var date = new Date();
    var time = `[${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}] `;
    // add message sent to the response list
    this.setState({ responseMessages: this.state.responseMessages.concat([`${time}Request Sent to ${url}`]) });

    fetch(url, options).then((response) => {
      if (response.status === 403) {
        localStorage.removeItem('token');
        window.location.reload();
        throw new Error('403 Forbidden')
      }
      if (response.ok) {
        localStorage.setItem('apiKey', this.state.apiKey);
      }
      time = `[${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}] `;
      // add the response message to the response list
      newRespMessage = `${time}Response: ${response.status}(${response.statusText})`;
      this.setState({ responseMessages: this.state.responseMessages.concat([newRespMessage]) });
    }).catch((e) => {
      console.log(e)
      newRespMessage = `${time}Error sending request: ${e}`
      this.setState({ responseMessages: this.state.responseMessages.concat([newRespMessage]) });
    });
  }

  makeMessageRequest(data) {
    var numRequests = data.numRequests || 1;
    let requestData = JSON.parse(JSON.stringify(data));
    if (requestData.alert === false) delete requestData.sound;
    delete requestData.numRequests;
    requestData.message_type = requestData.message_type || "text";
    requestData.direction = requestData.direction || "outbound";
    requestData.body = requestData.body || "test";

    if (this.state.showApiKeyInput && (!this.state.apiKey || this.state.apiKey.length === 0)) {
      alert("Please enter an API key");
      return;
    }
    if (this.state.environment === "production" && (!this.state.apiKey || this.state.apiKey.trim() === "")) {
      alert("API Key is required in production.");
      return;
    }
    if (requestData.message_type === "form" && !requestData.form_code) {
      alert("Form Code cannot be empty");
      return;
    }

    if (requestData.message_type === "form") {
      delete requestData.body;
      if (!requestData.form_data) {
        alert("Form Data cannot be empty");
        return;
      }
      try {
        JSON.parse(requestData.form_data);
      } catch {
        alert("Incorrect JSON for Form Data");
        return;
      }
    }

    const options = this.state.showApiKeyInput && this.state.apiKey
      ? ({
        method: 'PUT',
        headers: {
          "Authorization": `Key key=${this.state.apiKey}`,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        },
        body: encodeFormData(requestData)
      })
      : ({
        method: 'PUT',
        headers: {
          "Authorization": `Bearer ${this.state.token}`,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          "Eleos-Admin-Client-Key": this.state.clientKey || ""
        },
        body: encodeFormData(requestData)
      });

    for (var i = 0; i < numRequests; i++) {
      this.makeFetchRequest(options, "v1/messages");
    }
  }

  handleENVChange(event) {
    const newEnv = event.target.value;
    this.setState({
      environment: newEnv,
      showApiKeyInput: newEnv === "production",
    });
  }

  handleAPIChange(event) {
    this.setState({ apiKey: event.target.value });
  }

  toggleApiKeyInput() {
    this.setState(prevState => ({ showApiKeyInput: !prevState.showApiKeyInput }));
  }

  handleClientChange(event) {
    this.setState({ clientKey: event.target.value })
  }

  handleClientInputChange(event) {
    const value = event.target.value;
    this.setState({
      clientKeyInput: value,
      clientKey: value,
      showSuggestions: true
    });
  }

  handleClientSelect(key) {
    this.setState({
      clientKey: key,
      clientKeyInput: key,
      showSuggestions: false
    });
  }

  clearResponseLog() {
    this.setState({ responseMessages: [] });
  }

  handleResponse = (message) => {
    this.setState(prevState => ({
      responseMessages: prevState.responseMessages.concat([message])
    }));
  };

  toggleTab(tab) {
    this.setState({ selectedTab: tab });
  }

  render() {
    const { responseMessages, selectedTab, clientKeys, clientKeyInput, showSuggestions } = this.state;
    const filteredSuggestions = clientKeys.filter(key =>
      key.toLowerCase().includes(clientKeyInput.toLowerCase())
    ).slice(0, 10);

    const getResponseStyle = (message) => {
      if (message.includes("Response: 200")) {
        return { color: "#28a745" };
      } else if (
        message.includes("Response: 400") ||
        message.includes("Response: 401") ||
        message.includes("Response: 403") ||
        message.includes("Response: 404") ||
        message.includes("Response: 500")
      ) {
        return { color: "#dc3545" };
      }
      return {};
    };
    return (
      <div className="test-scripts-container">
        <header className='test-scripts-header'>
          <h1>API Test Dashboard</h1>
        </header>

        <section className="controls-section">
          <div className="input-group">
            <label className="input-label">
              <span>Environment:</span>
              <select
                name="environment"
                value={this.state.environment}
                onChange={this.handleENVChange}
                className="select-input uniform-width"
              >
                <option value="staging">Staging</option>
                <option value="production">Production</option>
              </select>
            </label>
            {!this.state.showApiKeyInput && this.state.isLoading && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <p style={{ marginRight: '1em' }}>Loading Clients...</p>
                <div className="loading-indicator"></div>
              </div>
            )}
            {!this.state.showApiKeyInput && this.state.clientKeys.length > 0 && (
              <label className="input-label client-key-search">
                <span>Client Key:</span>
                <div className="autocomplete-container" style={{ marginLeft: '1em' }}>
                  <input
                    type="text"
                    name="clientKey"
                    value={this.state.clientKeyInput}
                    onChange={this.handleClientInputChange}
                    onFocus={() => this.setState({ showSuggestions: true })}
                    onBlur={() => setTimeout(() => this.setState({ showSuggestions: false }), 200)}
                    className="text-input uniform-width"
                    placeholder="Search client key..."
                  />
                  {showSuggestions && filteredSuggestions.length > 0 && (
                    <ul className="suggestions-list">
                      {filteredSuggestions.map(key => (
                        <li
                          key={key}
                          className="suggestion-item"
                          onMouseDown={() => this.handleClientSelect(key)}
                        >
                          {key}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </label>
            )}
            {this.state.showApiKeyInput && (
              <label className="input-label">
                <span>API Key:</span>
                <input type="text" name="apiKey" value={this.state.apiKey} onChange={this.handleAPIChange} className="text-input uniform-width" style={{ marginLeft: '1em' }} placeholder="Enter API Key" />
              </label>
            )}
          </div>
        </section>
        <div className="split-container">
          <div className="left-panel">
            <section className='tabs-section'>
              <ul className='tabs'>
                <li className={selectedTab === 'messageApis' ? 'active' : ''} onClick={() => this.toggleTab('messageApis')}>Scripts</li>
                <li className={selectedTab === 'customMessage' ? 'active' : ''} onClick={() => this.toggleTab('customMessage')}>Custom Message</li>
                <li className={selectedTab === 'userRefresh' ? 'active' : ''} onClick={() => this.toggleTab('userRefresh')}>User Refresh</li>
                <li className={selectedTab === 'actionRequest' ? 'active' : ''} onClick={() => this.toggleTab('actionRequest')}>Action Request</li>
              </ul>
            </section>

            <section className='tab-content'>
              {selectedTab === 'messageApis' && (
                <div className="message-request-section">
                  <section className='test-buttons-section'>
                    <TestScriptButtons MessageFunction={this.makeMessageRequest} TtsText={this.TtsText} />
                  </section>
                </div>
              )}

              {selectedTab === 'customMessage' && (
                <div className="message-request-section">
                  <section className='custom-message-section'>
                    <CustomMessageForm MessageFunction={this.makeMessageRequest} />
                  </section>
                </div>
              )}

              {selectedTab === 'userRefresh' && (
                <div className="user-refresh-section">
                  <UserRefreshForm
                    apiKey={this.state.apiKey}
                    environment={this.state.environment}
                    token={this.state.token}
                    clientKey={this.state.clientKey}
                    onResponse={this.handleResponse}
                  />
                </div>
              )}

              {selectedTab === 'actionRequest' && (
                <div className="action-request-section">
                  <ActionRequestForm
                    apiKey={this.state.apiKey}
                    environment={this.state.environment}
                    token={this.state.token}
                    clientKey={this.state.clientKey}
                    onResponse={this.handleResponse}
                  />
                </div>
              )}
            </section>
          </div>
          <div className="right-panel">
            <section className="response-section">
              <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h3>Response Logs</h3>
                {this.state.responseMessages.length > 0 && (
                  <button onClick={this.clearResponseLog} className="clear-button">Clear</button>
                )}
              </div>
              <div className="response-container">
                {responseMessages.length === 0 ? (
                  <p className="no-logs">No logs yet.</p>
                ) : (
                  responseMessages.map((name, index) => (
                    <div
                      key={index}
                      className="response-item"
                      style={getResponseStyle(name)}
                    >
                      {name}
                    </div>
                  ))
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export { TestScripts }
