import React from 'react';
import './css/TestScriptButtons.css';

// needs MessageFunction,body,label,numRequests
class TextScriptButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      label: this.props.label,
      body: this.props.body,
    }
    this.sendMessage = this.sendMessage.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.displayEditForm = this.displayEditForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'alert' ? target.checked : target.value;
    const name = target.name;
    const newBody = {...this.state.body};
    newBody[name] = value;
    this.setState({
      body: newBody
    });
  }

  toggleForm() {
    this.setState({ open: !this.state.open });
  }

  displayEditForm() {
    return (
      <form className='TextScriptButton-form'>
        <label>
          Username:
          <input name="username" type="text" value={this.state.body.username} onChange={this.handleInputChange} />
        </label>
        <label>
          Body:
          <input name="body" type="text" value={this.state.body.body == null ? "test" : this.state.body.body} onChange={this.handleInputChange} />
        </label>
        <label style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          alert:
          <input style={{ marginLeft: '0.5em' }} name="alert" type="checkbox" checked={this.state.body.alert} onChange={this.handleInputChange} />
        </label>
        <label>
          Sound:
          <select value={this.state.body.sound} name="sound" onChange={this.handleInputChange}>
            <option value="none">None</option>
            <option value="default">Default</option>
            <option value="honk">Honk</option>
            <option value="guitar">Guitar</option>
            <option value="echo">Echo</option>
            <option value="marimba">Marimba</option>
          </select>
        </label>
        <label>
          text_to_speech_announcement :
          <input name="text_to_speech_announcement" type="text" value={this.state.body.text_to_speech_announcement} onChange={this.handleInputChange} />
        </label>
      </form>
    );
  }

  sendMessage(body) {
    this.props.MessageFunction(body);
  }

  render() {
    let { open } = this.state;
    return (
      <div>
        <li className='TextScriptButton-li'>
          <div className="button-container">
            <button className="button" onClick={this.sendMessage.bind(this, this.state["body"])}>
              {this.state["label"]} (User: {this.state["body"]["username"]})
            </button>
            <button className="edit" onClick={this.toggleForm}>
              Edit
            </button>
          </div>
          {open && this.displayEditForm()}
        </li>
      </div>
    );
  }

}

// needs MessageFunction, form_code, label, numRequests
class FormScriptButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      label: this.props.label,
      body: this.props.body,
    }
    this.sendMessage = this.sendMessage.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.displayEditForm = this.displayEditForm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'alert' ? target.checked : target.value;
    const name = target.name;
    const newBody = this.state.body;
    newBody[name] = value;
    this.setState({
      body: newBody
    });
  }

  toggleForm() {
    this.setState({ open: !this.state.open });
  }

  displayEditForm() {
    return (
      <form className="FormScriptButton-form">
        <label>
          Username:
          <input name="username" type="text" value={this.state.body.username} onChange={this.handleInputChange} />
        </label>
        <label>
          Form Code:
          <input name="form_code" type="text" value={this.state.body.form_code == null ? "test" : this.state.body.form_code} onChange={this.handleInputChange} />
        </label>
        <br />
        <label>
          <p>Form Data:</p>
          <textarea cols={50} rows={3} name="form_data" type="text" value={this.state.body.form_data} onChange={this.handleInputChange} />
        </label>
        <br />
        <label style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
          alert:
          <input style={{ marginRight: '0.5em' }} name="alert" type="checkbox" checked={this.state.body.alert} onChange={this.handleInputChange} />
        </label>
        <label>
          Sound:
          <select value={this.state.body.sound} name="sound" onChange={this.handleInputChange}>
            <option value="none">None</option>
            <option value="default">Default</option>
            <option value="honk">Honk</option>
            <option value="guitar">Guitar</option>
            <option value="echo">Echo</option>
            <option value="marimba">Marimba</option>
          </select>
        </label>
        <label>
          text_to_speech_announcement :
          <input name="text_to_speech_announcement" type="text" value={this.state.body.text_to_speech_announcement} onChange={this.handleInputChange} />
        </label>
      </form>
    );
  }



  sendMessage(body) {
    this.props.MessageFunction(body);
  }

  render() {
    let { open } = this.state;
    return (
      <div>
        <li className='FormScriptButton-li'>
          <div className="button-container">
            <button className="button" onClick={this.sendMessage.bind(this, this.state["body"])}>
              {this.state["label"]} (User: {this.state["body"]["username"]})
            </button>
            <button className="edit" onClick={this.toggleForm}>
              Edit
            </button>
          </div>
          {open && this.displayEditForm()}
        </li>
      </div>
    );
  }

}


class TestScriptButtons extends React.Component {
  constructor(props) {
    super(props);
    this.sendMessage = this.sendMessage.bind(this);
    this.createMessageButton = this.createMessageButton.bind(this);
  }

  sendMessage(body) {
    this.props.MessageFunction(body);
  }

  createMessageButton(body, label) {
    return (
      <li>
        <button className="button" onClick={this.sendMessage.bind(this, body)}>
          {label} (User: {body["username"]})
        </button>
      </li>
    );

  }

  render() {
    const TtsText = this.props.TtsText;
    return (
      <div className='TestScriptButtons-container'>
        <div>
          <h3>Message Scripts</h3>
          <ul style={{paddingInlineStart: 0}}>
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C303", alert: false }} label="Message Without Alert" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C303", alert: true, sound: "default" }} label="Message With Default Alert" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C303", alert: true, sound: "honk" }} label="Message With Honk Alert" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "none" }} label="Message With Alert But No Sound" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C1222", alert: false, sound: "none", numRequests: 200 }} label="Send 200 Messages" />
            <FormScriptButton MessageFunction={this.sendMessage} body={{ username: "C303", alert: true, sound: "default", form_code: "1.39-START-STOP-O", form_data: `{"LOAD-NUMBER": "123","STOP-NUMBER": "2"}`, message_type: "form" }} label="Form Message with Default Alert" />
          </ul>
        </div>
        <div>
          <h3>TTS Scripts</h3>
          <ul style={{paddingInlineStart: 0}}>
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "honk", text_to_speech_announcement: TtsText["normalTTS"] }} label="Normal TTS" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "honk", text_to_speech_announcement: TtsText["longTTS"] }} label="Long TTS" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "honk", text_to_speech_announcement: TtsText["pokemonTTS"] }} label="Pokemon TTS" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "honk", text_to_speech_announcement: "" }} label="Empty TTS Message" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "honk", text_to_speech_announcement: TtsText["commonAbrevTTS"] }} label="TTS With Common Abreviations" />
            <TextScriptButton MessageFunction={this.sendMessage} body={{ username: "C4203", alert: true, sound: "honk", text_to_speech_announcement: TtsText["spanishTTS"] }} label="Spanish TTS" />
          </ul>
        </div>
      </div>
    );
  }
}

export default TestScriptButtons;
