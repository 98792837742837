import React from 'react';
import './css/CustomMessageForm.css';

class CustomMessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numRequests: 1,
      message_type: "text",
      direction: "outbound",
      body: "test",
      username: "",
      alert: true,
      sound: "none",
      text_to_speech_announcement: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'alert' ? target.checked : target.value;
    const name = target.name;

    if (name === "message_type") {
      if (value === "text") {
        delete this.state.form_code;
        delete this.state.form_data;
        this.setState({ body: "test" });
      } else if (value === "form") {
        delete this.state.body;
        this.setState({
          form_code: "1.39-START-STOP-O",
          form_data: '{"LOAD-NUMBER": "123","STOP-NUMBER": "2"}'
        });
      }
    }

    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.username.length === 0) {
      alert("Username field required for custom message");
    } else if (!this.state.body && !this.state.form_code) {
      alert("Body or Form Code field required for custom message");
    } else if (this.state.numRequests > 200) {
      alert("Number of Messages must be less than 200 for custom message");
    } else {
      this.props.MessageFunction(this.state);
    }
  }

  handleClearForm() {
    this.setState({
      numRequests: 1,
      message_type: "text",
      direction: "outbound",
      body: "test",
      username: "",
      alert: true,
      sound: "none",
      text_to_speech_announcement: "",
      form_code: undefined,
      form_data: undefined
    });
  }

  render() {
    const handleMessageTypeSwitch = () => {
      if (this.state.message_type === 'text') {
        return (
          <>
            <label className="CustomMessageForm-label">
              Body:
              <input name="body" type="text" value={this.state.body} onChange={this.handleInputChange} className="CustomMessageForm-input" />
            </label>
          </>
        );
      } else {
        return (
          <>
            <label className="CustomMessageForm-label">
              Form Code:
              <input name="form_code" type="text" value={this.state.form_code} onChange={this.handleInputChange} className="CustomMessageForm-input" />
            </label>
            <label className="CustomMessageForm-label">
              <p>Form Data:</p>
              <textarea
                className="CustomMessageForm-textarea form_data_textarea"
                cols={50}
                rows={4}
                name="form_data"
                value={this.state.form_data}
                onChange={this.handleInputChange}
              />
            </label>
          </>
        );
      }
    };

    return (
      <div className="CustomMessageForm-container">
        <h3>Custom Message</h3>
        <form className="CustomMessageForm-form" onSubmit={this.handleSubmit}>
          <label className="CustomMessageForm-label">
            Direction:
            <select value={this.state.direction} name="direction" onChange={this.handleInputChange} className="CustomMessageForm-select">
              <option value="outbound">Outbound</option>
              <option value="inbound">Inbound</option>
            </select>
          </label>
          <label className="CustomMessageForm-label">
            Message Type:
            <select value={this.state.message_type} name="message_type" onChange={this.handleInputChange} className="CustomMessageForm-select">
              <option value="text">Text</option>
              <option value="form">Form</option>
            </select>
          </label>
          {handleMessageTypeSwitch()}
          <label className="CustomMessageForm-label">
            Number of Messages:
            <input
              type='number'
              name="numRequests"
              value={this.state.numRequests}
              onChange={event => this.setState({ numRequests: event.target.value.replace(/\D/, '') })}
              className="CustomMessageForm-input"
            />
          </label>
          <label className="CustomMessageForm-label">
            Username:
            <input name="username" type="text" value={this.state.username} onChange={this.handleInputChange} className="CustomMessageForm-input" />
          </label>
          <label className="CustomMessageForm-label CustomMessageForm-checkbox-label">
            Alert:
            <input
              name="alert"
              type="checkbox"
              checked={this.state.alert}
              onChange={this.handleInputChange}
              className="CustomMessageForm-checkbox"
            />
          </label>
          <label className="CustomMessageForm-label">
            Sound:
            <select value={this.state.sound} name="sound" onChange={this.handleInputChange} className="CustomMessageForm-select">
              <option value="none">None</option>
              <option value="default">Default</option>
              <option value="honk">Honk</option>
              <option value="guitar">Guitar</option>
              <option value="echo">Echo</option>
              <option value="marimba">Marimba</option>
            </select>
          </label>
          <label className="CustomMessageForm-label">
            text_to_speech_announcement:
            <input
              name="text_to_speech_announcement"
              type="text"
              value={this.state.text_to_speech_announcement}
              onChange={this.handleInputChange}
              className="CustomMessageForm-input"
            />
          </label>
          <div className="CustomMessageForm-button-group">
            <input type="submit" value="Send" className="CustomMessageForm-send-button" />
            <button type="button" className="CustomMessageForm-clear-button" onClick={this.handleClearForm}>Clear</button>
          </div>
        </form>
      </div>
    );
  }
}

export default CustomMessageForm;